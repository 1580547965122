import React, { createContext, useState, useEffect } from 'react';

import axiosRequest from 'utils/axiosRequest';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const UsersContext = createContext();
const UsersProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const clearError = () => {
    setError(null);
  };
  const showError = (message) => {
    setError(message);
  };
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [toggleSelectEnable, setToggleSelectEnable] = useState(false);
  const getUsers = async () => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}user`, null, null, navigate);

      if (response?.success) {
        setUsers(response.data);
      } else {
        showError(response?.message);
      }
      return response.data;
    } catch (error) {
      return showError('An error occurred while fetching data.');
    }
  };

  
  const getAgents = async () => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}user/agents`, null, null);
      setAgents(response?.data);

      return response?.data;
    } catch (error) {
      return console.log(error);
    }
  };
  const getAgentById = async (id) => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}user/agents/${id}`, null, null);

      return response?.data;
    } catch (error) {
      return console.log(error);
    }
  };

  const updateAgent = async (arg) => {
    try {
      const response = await axiosRequest('PUT', `${config.apiUrl}user/agents/${arg.id}`, null, arg.payload, navigate);
      
      setAgents(response.data);
      return response.data;
    } catch (error) {
      return console.log(error);
    }
  };

  const createtAgent = async (arg) => {
    try {
      const data = await axiosRequest('POst', `${config.apiUrl}agents/create_agent`, null, arg, navigate);

      setAgents([...agents, data.data]);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const createCalendar = async (arg) => {
    try {
      const data = await axiosRequest('POst', `${config.apiUrl}user/agents/${arg}/calendar_settings`, null, null, navigate);

      // setAgents([...agents, data.data]);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCalendar = async (arg) => {
    try {
      const data = await axiosRequest('PUT', `${config.apiUrl}user/agents/${arg.agent_id}/calendar_settings/${arg.calendar_id}`, null, arg.payload, navigate);

      // setAgents([...agents, data.data]);
      return data;
    } catch (error) {
      console.log(error);
    }
  };


  const deleteCalendar = async (arg) => {
    try {
      const data = await axiosRequest('DELETE', `${config.apiUrl}user/agents/${arg.agent_id}/calendar_settings/${arg.calendar_id}`, null, arg.payload, navigate);

      // setAgents([...agents, data.data]);
      return data;
    } catch (error) {
      console.log(error);
    }
  };


  const getAnalytics = async (selectedAgent) => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}user/analitics?agent_filter=${selectedAgent}`, null, null, navigate);

      if (response?.success) {
        setUsers(response);
      } else {
        showError(response?.message);
      }
      return response;
    } catch (error) {
      return showError('An error occurred while fetching data.');
    }
  };
  const populateAgentData = async (arg) => {
    try {
      const data = await axiosRequest('POst', `${config.apiUrl}user/agents/${arg.id}/ai_populate`, null, arg.data, navigate);
      // setAgents([...agents, data.data]);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const logoutUser = async (arg) => {
    try {
      const data = await axiosRequest('POst', `${config.apiUrl}logout`, null, null, navigate);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const demoAgent = async (arg) => {
    try {
      const data = await axiosRequest('POst', `${config.apiUrl}user/agents/${arg.id}/demo`, null, arg.payload, navigate);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const cloneAgent = async (arg) => {
    try {
      const res = await axiosRequest('POst', `${config.apiUrl}user/agents/${arg}/duplicate`, null, null, navigate);
      setAgents(res.data);
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const reloadSources = async (arg) => {
    try {
      const data = await axiosRequest('POST', `${config.apiUrl}user/reload_sources`, null, null, navigate);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateAccount = async (arg) => {
    try {
      const data = await axiosRequest('put', `${config.apiUrl}user`, null, arg);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateAgentFaq = async (arg) => {
    try {
      const data = await axiosRequest('put', `${config.apiUrl}user/agents/${arg.id}`, null, arg.payload, navigate);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAgent = async (arg) => {
    try {
      const data = await axiosRequest('delete', `${config.apiUrl}user/agents/${arg}`, null, arg.payload, navigate);
      setAgents([...agents.filter((agent) => agent.id != arg)]);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const createContact = async (arg) => {
    try {
      const data = await axiosRequest('POst', `${config.apiUrl}simulate/contact_created`, null, arg.data, navigate);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        error,
        clearError,
        showError,
        agents,
        users,
        selectedContacts,
        toggleSelectEnable,
        setToggleSelectEnable,
        setSelectedContacts,
        demoAgent,
        updateAccount,
        updateAgentFaq,
        reloadSources,
        logoutUser,
        createtAgent,
        cloneAgent,
        getUsers,
        createContact,
        createCalendar,
        deleteCalendar,
        getAnalytics,
        getAgents,
        getAgentById,
        updateCalendar,
        updateAgent,
        deleteAgent,
        populateAgentData
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { UsersProvider, UsersContext };
